<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn
          v-bind="attrs"
          icon
          small
          :color="isDisabled ? 'secondary' : 'primary'"
          :outlined="hover"
          v-on="on"
          @click="onCheckedRead()"
        >
          <div
            :class="[isDisabled ? 'secondary' : 'primary', isMobile ? 'circle mobile' : 'circle']"
          />
        </v-btn>
      </v-hover>
    </template>
    <span>
      {{ isDisabled ? $t('notification_bell.viewed') : $t('notification_bell.mark_as_read') }}
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'ButtonReadNotification',
    props: {
      value: {
        type: [String, Number, Boolean],
        default: null
      }
    },
    computed: {
      isDisabled() {
        return !!this.value
      },
      isMobile() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      onCheckedRead() {
        if (!this.value) {
          this.$emit('input', true)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .circle {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 2px;
  }

  .mobile {
    width: 15px;
    height: 15px;
  }
</style>
