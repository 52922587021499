<template>
  <div v-if="notificationModelIsCampaign && notificationModel.id">
    <a
      :loading="viewIsLoading"
      class="text-decoration-underline"
      target="_blank"
      @click="redirectToCampaign()"
    >
      {{ $t('notification_bell.view') }} {{ getTranslatedModelType(notificationModel.type) }} #{{
        notificationModel.id
      }}
    </a>
  </div>
</template>

<script>
  import ValidationError from '@/services/classes/validation-error.js'
  import { modelTypes } from '@/views/Notification/NotificationBell/notificationt-types.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'ViewLink',
    props: {
      notificationModel: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        viewIsLoading: false
      }
    },
    computed: {
      notificationModelIsCampaign() {
        return this.notificationModel && this.notificationModel.type === modelTypes.CAMPAIGN
      },
      getTranslatedModelType() {
        return (type) => ({
          [modelTypes.CAMPAIGN]: this.$t('notification_bell.campaign_2'),
          [modelTypes.AD]: this.$t('notification_bell.ad')
        }[type])
      }
    },
    methods: {
      getRedirectNameByModelType(type) {
        return {
          [modelTypes.CAMPAIGN]: routeNames.CAMPAIGN,
          [modelTypes.AD]: routeNames.AD
        }[type]
      },
      async redirectToCampaign() {
        this.viewIsLoading = true

        const oldParamsId = this.$route.params.id

        try {
          await this.$router.push({
            name: this.getRedirectNameByModelType(this.notificationModel.type),
            params: { id: this.notificationModel.id }
          })

          if (!!oldParamsId && oldParamsId !== this.notificationModel.id) {
            window.location.reload()
          }
        } catch (error) {
          if (error instanceof ValidationError) {
            this.$showErrorNotification(error)
            return
          }

          this.$showErrorNotification(
            `${this.$t('notification_bell.already_on_campaign')} #${this.notificationModel.id}`
          )
        } finally {
          this.viewIsLoading = false
        }
      }
    }
  }
</script>
