<template>
  <v-dialog :value="isShown" persistent max-width="497">
    <v-card class="pb-5 pb-sm-8">
      <v-card-title class="d-block global-break-normal pb-5">
        <close-button right absolute @click="closeDialog()" />

        <div
          class="d-flex justify-center text-center body-1 font-weight-medium black--text pt-8 pr-5"
        >
          {{ title }}
        </div>
      </v-card-title>

      <v-card-actions class="justify-center">
        <c-btn
          large
          depressed
          color="primary"
          class="py-3 px-10"
          :label="$t('notification_bell.no_delete')"
          @click="closeDialog()"
        />

        <c-btn
          large
          depressed
          class="py-3 px-10 border-black"
          color="white"
          :label="$t('notification_bell.delete')"
          @click="$emit('remove-notifications')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CloseButton from '@/components/CloseButton.vue'

  export default {
    name: 'RemoveNotificationDialog',
    components: {
      CBtn,
      CloseButton
    },
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .border-black {
    border: 1px solid black !important;
  }
</style>
